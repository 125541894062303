<template>
  <div :class="classList.modal">
    <div :class="classList.modalDialog">
      <div :class="classList.modalContent">
        <button :class="classList.closeButton" @click="handleCloseButtonClick">
          <CloseIcon />
        </button>

        <div :class="classList.modalHeader">
          <h5 :class="classList.modalTitle">
            {{ t('title') }}
          </h5>
        </div>
        <div :class="classList.modalBody">
          <Message :type="messageType" :message="message" />
          <Form :class="classList.form" :ref="formRef">
            <section :class="classList.fieldContainer">
              <TextField
                :label="t('email')"
                :type="inputType.EMAIL"
                :name="name.RESEND_CONFIRMATION_EMAIL"
                :validators="[validator.required, validator.email]"
                v-model="formData[name.RESEND_CONFIRMATION_EMAIL]"
                :serverError="serverError[name.RESEND_CONFIRMATION_EMAIL]"
                :labelClass="classList.label"
              />
            </section>
            <button
              :class="classList.submitButton"
              @click="submitForm"
              :disabled="isSubmitting"
            >
              <ButtonLoader v-if="isSubmitting" />
              <span v-else>{{ t('resend') }}</span>
            </button>
            &nbsp;
          </Form>
          <div :class="classList.line"></div>
          <p :class="classList.footer">
            {{ t('need_help') }}
            <button
              :class="classList.footerButton"
              @click="handleFooterButtonClick"
            >
              {{ t('click_here') }}
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formMixin, modalMixin } from '@/mixins'
import { UserApi } from '@/api'
import {
  FORGOT_PASSWORD_MODAL,
  EventType,
  EMPTY_STRING,
  MODAL_DIALOG,
  MODAL_CONTENT,
  MODAL_HEADER,
  MODAL_TITLE,
  MODAL_BODY,
  CSN_MODAL_CLOSE_BUTTON,
  LINE_CLASS,
  FOOTER_CLASS,
  BTN_CLASS,
} from '@/constants'

const CSN_FORGOT_PASSWORD = 'csn-forgot-password'
const CSN_FORGOT_PASSWORD_FIELD_CONTAINER = `${CSN_FORGOT_PASSWORD}-field-container`
const CSN_FORGOT_PASSWORD_FOOTER = `${CSN_FORGOT_PASSWORD}-footer`
const FORGOT_PASSWORD_MODAL_HEADER = `${CSN_FORGOT_PASSWORD}-header`
const formClass = `col-7 mx-auto csn-modal-content-form`
const submitButtonClass = `${BTN_CLASS} casino-btn casino-btn-main-menu pull-left btn-full-width`
const CSN_MODEL_CONTENT_REDIRECT_BUTTON = 'csn-modal-content-redirect-button'
const CSN_FORGOT_PASSWORD_EMAIL_LABEL = 'csn-forgot-password-email-label'

const USER_FORGET_PASSWORD = 'user_forget_password'

const name = {
  FORGET_PASSWORD_EMAIL: 'FORGET_PASSWORD_EMAIL',
}
const formData = {
  [name.FORGET_PASSWORD_EMAIL]: EMPTY_STRING,
}

export default {
  name: FORGOT_PASSWORD_MODAL,
  mixins: [formMixin, modalMixin],
  components: {
    Form: () => import('@/components/FormData'),
    TextField: () => import('@/components/TextField'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
  },
  data() {
    return {
      formData: { ...formData },
    }
  },
  computed: {
    name: () => name,
    classList: () => ({
      modal: CSN_FORGOT_PASSWORD,
      modalDialog: MODAL_DIALOG,
      modalContent: MODAL_CONTENT,
      closeButton: [BTN_CLASS, CSN_MODAL_CLOSE_BUTTON],
      modalHeader: [MODAL_HEADER, FORGOT_PASSWORD_MODAL_HEADER],
      modalTitle: MODAL_TITLE,
      modalBody: MODAL_BODY,
      form: formClass,
      fieldContainer: CSN_FORGOT_PASSWORD_FIELD_CONTAINER,
      submitButton: submitButtonClass,
      line: LINE_CLASS,
      footer: [FOOTER_CLASS, CSN_FORGOT_PASSWORD_FOOTER],
      footerButton: CSN_MODEL_CONTENT_REDIRECT_BUTTON,
      label: CSN_FORGOT_PASSWORD_EMAIL_LABEL,
    }),
    t() {
      return this.$createComponentTranslator(FORGOT_PASSWORD_MODAL)
    },
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.message && (this.message = null)
        this.messageType && (this.messageType = null)
      },
    },
  },
  methods: {
    closeModal(fn) {
      return this.$emit(EventType.CLOSE, fn)
    },
    handleCloseButtonClick() {
      this.closeModal(this.goToHomepage)
    },
    handleFooterButtonClick() {
      this.closeModal(this.goToNeedHelp)
    },
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }
      try {
        this.isSubmitting = true

        const request = {
          [USER_FORGET_PASSWORD]: {
            email: this.formData[this.name.RESEND_CONFIRMATION_EMAIL],
          },
        }

        const response = await UserApi.postForgetPassword(request)

        if (!this.validateResponse(response)) {
          return
        }

        const { state, msg } = response
        await this.$refs[this.formRef].reset()

        if (state === this.responseState.ERROR) {
          this.messageType = this.messageTypeEnum.FAILURE
          this.message = msg
          return
        }

        this.messageType = this.messageTypeEnum.SUCCESS
        this.message = msg
      } catch (e) {
        console.dir(e)
      } finally {
        this.$refs[this.formRef].reset()
        this.isSubmitting = false
      }
    },
  },
}
</script>
